// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationGuestSheetActions__reservationActions___GZfYi{display:flex;row-gap:8px;align-items:stretch;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/reservationGuestSheet/ReservationGuestSheetActions.scss"],"names":[],"mappings":"AAAA,0DACE,YAAA,CACA,WAAA,CACA,mBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reservationActions": "ReservationGuestSheetActions__reservationActions___GZfYi"
};
export default ___CSS_LOADER_EXPORT___;
