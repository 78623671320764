/* eslint-disable import/order */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { successToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { EditPricePointsPayload, Listing } from '../apiHelpers';
import { editListing, getListing } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import {
  listingsPagePath,
  ListingsPageScope,
} from '../listingsPage/listingsPagePath';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';

interface ModalState {
  currentModal: 'error' | 'warning' | null;
  isSavingDraft?: boolean;
  message?: string;
}

export interface EditListingContextState {
  clearModalState: () => void;
  existingListing: Listing | undefined;
  isListingLoading: boolean;
  modalState: ModalState;
  setModalState: (modalState: ModalState) => void;
  processEditSubmission: (
    highlightedFloorPlanTableIds: string[],
    ignoreWarnings: boolean,
    updatedListing: Listing,
    pricePointsPayload?: EditPricePointsPayload,
  ) => Promise<void>;
}

export const EditListingContext = createContext<EditListingContextState>(
  {} as EditListingContextState,
);
EditListingContext.displayName = 'EditListingContext';

export const useEditListingContext = (): EditListingContextState =>
  useContext(EditListingContext);

export const EditListingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();
  const { refreshFloorPlan, refreshListings, setSelectedListingId, subView } =
    useListingsContext();
  const { listingId } = useParams();
  const [modalState, setModalState] = useState<ModalState>({} as ModalState);
  const navigate = useNavigate();
  if (!listingId) {
    throw new Error('Listing id not provided-context');
  }

  const { data: existingListing, isPending: isListingLoading } = useAbortEffect(
    (signal) => getListing(listingId, restaurantId, signal),
    [restaurantId, listingId],
  );

  const clearModalState = () => {
    setModalState({ currentModal: null });
  };

  const processEditSubmission = async (
    highlightedFloorPlanTableIds: string[],
    ignoreWarnings: boolean,
    updatedListing: Listing,
    pricePointsPayload?: EditPricePointsPayload,
  ): Promise<void> => {
    const isDraft = updatedListing.status === 'draft';
    let response;
    try {
      response = await editListing(
        updatedListing.restaurantId,
        updatedListing.id,
        {
          endDate: updatedListing.endDate || undefined,
          endTime: updatedListing.endTime,
          highlightedFloorPlanTableIds,
          iconName: updatedListing.iconName,
          ignoreWarnings,
          interval: updatedListing.interval,
          inventoryCount: Number(updatedListing.inventoryCount),
          maximumGuests: Number(updatedListing.maximumGuests),
          minimumGuests: Number(updatedListing.minimumGuests),
          name: updatedListing.name,
          price: updatedListing.price,
          pricePoints: pricePointsPayload,
          publicName: updatedListing.publicName,
          repeat: updatedListing.repeat,
          startDate: updatedListing.startDate,
          startTime: updatedListing.startTime,
        },
      );
    } catch (e) {
      if (e instanceof ApiError) {
        setModalState({
          currentModal: 'error',
          isSavingDraft: isDraft,
          message: e.message,
        });
      }
      reportAppError(e);
      return;
    }

    if (response.warnings.length > 0) {
      setModalState({
        currentModal: 'warning',
        isSavingDraft: isDraft,
        message: response.warnings[0],
      });
    } else {
      refreshListings();
      refreshFloorPlan();
      successToast({
        message: 'Listing successfully updated',
      });
      setSelectedListingId(updatedListing.id);
      navigate(
        listingsPagePath({
          scope: isDraft
            ? ListingsPageScope.Draft
            : ListingsPageScope.Published,
          subView,
        }),
      );
    }
  };

  const value = useMemo<EditListingContextState>(
    () => ({
      clearModalState,
      existingListing,
      isListingLoading,
      listingId,
      modalState,
      processEditSubmission,
      setModalState,
    }),
    [modalState, listingId, isListingLoading],
  );

  return (
    <EditListingContext.Provider value={value}>
      {children}
    </EditListingContext.Provider>
  );
};
