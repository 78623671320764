import { useReducer } from 'react';

/**
 * Returns a number and function that increments that number.
 *
 * Useful for creating a function that causes a useEffect to re-run by changing
 * one of its dependencies.
 *
 * Example:
 * ```javascript
 * const [invalidateVal, invalidate] = useInvalidate();
 * useEffect(() => {
 *   console.log("The button was clicked");
 * }, [invalidateVal]);
 * const onClickButton = () => invalidate();
 * ```
 */
export const useInvalidate = (): [number, () => void] =>
  useReducer((n: number) => n + 1, 0);
