import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useInvalidate } from '@shared/hooks/useInvalidate';
import { getFloorPlanForRestaurantId } from 'restaurantAdmin/floorPlans/apiHelpers';
import { useRestaurant } from '../context/useRestaurant';

export const useAdminFloorPlan = (isDynamic: boolean) => {
  const { id: restaurantId } = useRestaurant();
  const [refetchDep, fetchFloorPlan] = useInvalidate();

  const { data: floorPlan, isPending: isLoading } = useAbortEffect(
    {
      effect: () => getFloorPlanForRestaurantId(restaurantId, isDynamic),
      throwOnError: true,
    },
    [restaurantId, refetchDep],
  );

  return { floorPlan, isLoading, fetchFloorPlan };
};
