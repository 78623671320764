import cx from 'classnames';
import { useState } from 'react';
import { Icon } from '@components/icon/Icon';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useInvalidate } from '@shared/hooks/useInvalidate';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { getGuestTagsForGuest } from '../../guestBook/apiHelpers';
import { GuestTagEditForm } from './GuestTagEditForm/GuestTagEditForm';
import { GuestTagList } from './GuestTagList/GuestTagList';
import styles from './GuestTags.scss';

export interface GuestTagsProps {
  guestId: string;
  onUpdate?: () => void;
}

const DEFAULT_ON_UPDATE_CALLBACK = () => null;

export const GuestTags = ({
  guestId,
  onUpdate = DEFAULT_ON_UPDATE_CALLBACK,
}: GuestTagsProps) => {
  const [isEditingGuestTags, setIsEditingGuestTags] = useState(false);
  const { id: restaurantId } = useRestaurant();
  const [refreshDep, refresh] = useInvalidate();

  const { data: guestTags = [], isPending: isLoading } = useAbortEffect(
    () => getGuestTagsForGuest(restaurantId, guestId),
    [restaurantId, guestId, refreshDep],
  );

  if (isLoading) {
    return (
      <section className={styles.container}>
        <h3 className={cx(typography.h7, styles.heading)}>Tags</h3>
        <p>Loading...</p>
      </section>
    );
  }

  if (isEditingGuestTags) {
    return (
      <section className={styles.containerEdit}>
        <h3 className={cx(typography.h7, styles.heading)}>Tags</h3>
        <GuestTagEditForm
          guestId={guestId}
          guestTags={guestTags}
          onComplete={() => {
            setIsEditingGuestTags(false);
            refresh();
            void onUpdate();
          }}
        />
      </section>
    );
  }

  return (
    <section className={styles.container} aria-labelledby="guest-tags-section">
      <header>
        <h3
          className={cx(typography.h7, styles.heading)}
          id="guest-tags-section"
        >
          Tags
        </h3>
        <button onClick={() => setIsEditingGuestTags(true)}>
          <Icon className={styles.icon} name="pencil" />
          Edit
        </button>
      </header>
      <GuestTagList guestTags={guestTags} />
    </section>
  );
};
